import React from "react";

export default function CeresLogo({ width = 32, height = 32, className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      //   width={width}
      //   height={height}
      className={className}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 256 228"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.16 340.16">
        <g id="Layer_2" data-name="Layer 2">
          <g id="图层_1" data-name="图层 1">
            <path
              fill="#5746af"
              d="M116.49,276.21a106.13,106.13,0,1,1,96.88-149.53l8.67-5.91a116.48,116.48,0,1,0,0,98.62l-8.67-5.92A106.16,106.16,0,0,1,116.49,276.21Z"
            />
            <path
              fill="#5746af"
              d="M223.67,64a106.13,106.13,0,1,1-96.88,149.52l-8.67,5.92a116.49,116.49,0,1,0,0-98.62l8.67,5.91A106.15,106.15,0,0,1,223.67,64Z"
            />
            <path
              fill="#5746af"
              d="M64,116.49a106.13,106.13,0,1,1,149.52,96.88l5.92,8.67a116.49,116.49,0,1,0-98.62,0l5.91-8.67A106.15,106.15,0,0,1,64,116.49Z"
            />
            <path
              fill="#5746af"
              d="M276.21,223.67a106.13,106.13,0,1,1-149.53-96.88l-5.91-8.67a116.48,116.48,0,1,0,98.62,0l-5.92,8.67A106.16,106.16,0,0,1,276.21,223.67Z"
            />
            <path
              fill="#5746af"
              d="M207.23,283A106.13,106.13,0,1,1,170,108.78L172,98.47a116.48,116.48,0,1,0,69.74,69.73l-10.32,2A106.15,106.15,0,0,1,207.23,283Z"
            />
            <path
              fill="#5746af"
              d="M132.93,57.14a106.13,106.13,0,1,1,37.23,174.23l-2,10.32A116.49,116.49,0,1,0,98.47,172L108.78,170A106.15,106.15,0,0,1,132.93,57.14Z"
            />
            <path
              fill="#5746af"
              d="M57.14,207.23A106.13,106.13,0,1,1,231.37,170L241.69,172A116.49,116.49,0,1,0,172,241.69L170,231.37A106.14,106.14,0,0,1,57.14,207.23Z"
            />
            <path
              fill="#5746af"
              d="M283,132.93a106.13,106.13,0,1,1-174.24,37.23l-10.31-2A116.48,116.48,0,1,0,168.2,98.47l2,10.31A106.15,106.15,0,0,1,283,132.93Z"
            />
          </g>
        </g>
      </svg>
    </svg>
  );
}
